/* Hero Section Styles */
.hero-section {
    position: relative;
    height: 100vh;
    background: url('/public/assets/SolarPanel.jpg') no-repeat center center/cover;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-align: center;
    overflow: hidden;
  }
  
  .hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6); /* Dark overlay on background image */
    z-index: 1;
  }
  
  .hero-content {
    position: relative;
    z-index: 2;
    max-width: 700px;
    padding: 0 20px;
  }
  
  .hero-title {
    font-size: 4rem;
    font-weight: 700;
    margin-bottom: 20px;
    text-transform: uppercase;
    line-height: 1.2;
    letter-spacing: 2px;
  }
  
  .hero-subtitle {
    font-size: 1.5rem;
    font-weight: 300;
    margin-bottom: 30px;
    line-height: 1.5;
  }
  
  .hero-button {
    background-color: #007bff;
    color: #fff;
    padding: 15px 30px;
    font-size: 1.2rem;
    border: none;
    border-radius: 5px;
    text-transform: uppercase;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .hero-button:hover {
    background-color: #0056b3;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .hero-title {
      font-size: 3rem;
    }
  
    .hero-subtitle {
      font-size: 1.2rem;
    }
  
    .hero-button {
      padding: 12px 25px;
      font-size: 1rem;
    }
  }
  
  @media (max-width: 480px) {
    .hero-title {
      font-size: 2.5rem;
    }
  
    .hero-subtitle {
      font-size: 1rem;
    }
  
    .hero-button {
      padding: 10px 20px;
      font-size: 0.9rem;
    }
  }
  