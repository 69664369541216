.testimonial-section {
  padding: 2rem;
  background-color: #f7f7f7;
  text-align: center;
}

.testimonial-section h2 {
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: #333;
}

/* Horizontal layout for testimonials */
.testimonial-list {
  display: flex;
  justify-content: center;
  gap: 2rem; /* Space between each testimonial */
  overflow-x: auto; /* Enable horizontal scrolling on smaller screens */
  padding: 2rem 0;
}

.testimonial-item {
  flex: 0 0 auto; /* Prevent items from shrinking */
  width: 300px; /* Set a fixed width for each testimonial */
  padding: 2rem;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.testifier-image {
  width: 150px; /* Adjust size for image */
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 1.5rem;
}

blockquote {
  font-size: 1.2rem;
  color: #555;
  line-height: 1.8;
  margin: 0;
  text-align: center;
}

blockquote p {
  font-style: italic;
}

footer {
  margin-top: 1.5rem;
  font-size: 1rem;
  color: #333;
}

footer strong {
  font-weight: 700;
}

footer em {
  font-style: normal;
  color: #777;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .testimonial-item {
    width: 250px;
  }

  .testifier-image {
    width: 100px;
    height: 100px;
  }

  blockquote {
    font-size: 1.1rem;
  }

  footer {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .testimonial-item {
    width: 200px;
  }

  .testifier-image {
    width: 80px;
    height: 80px;
  }

  blockquote {
    font-size: 1rem;
  }

  footer {
    font-size: 0.8rem;
  }
}
