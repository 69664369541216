/* Navbar.css */
.navbar {
  display: flex;
  justify-content: center; /* Center the logo and nav-links */
  align-items: center;
  padding: 20px;
  background-color: #333;
  position: relative;
}

.navbar .logo {
  margin-right: 20px;
  font-size: 1.5rem;
  font-weight: bold;
  position: absolute;
  left: 60Px; /* Position the logo to the left */
}

.navbar .logo img {
  width: 100px; /* Adjust the logo size as needed */
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 30px; /* Space between the links */
}

.nav-links li {
  display: inline; /* Ensures links are inline */
}

.nav-links a {
  text-decoration: none;
  color: white;
  font-size: 1.1rem;
  transition: color 0.3s ease;
}

.nav-links a:hover {
  color: #007bff; /* Change link color on hover */
}
