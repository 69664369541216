/* src/components/Footer.css */
.footer-container {
  background-color: black; /* Set the footer background to black */
  color: white; /* Set text color to white */
  padding: 20px; /* Add some padding */
  text-align: center; /* Center the text */
  position: relative;
  bottom: 0;

}

.footer-links {
  display: flex; /* Use flexbox for layout */
  justify-content: center; /* Center the links */
  gap: 20px; /* Space between the links */
  flex-wrap: wrap; /* Allow wrapping of links */
}

.footer-link {
  color: white; /* Link color */
  text-decoration: none; /* Remove underline from links */
  font-size: 18px; /* Increase font size */
}

.footer-link:hover {
  color: #f0a500; /* Change color on hover */
}

.footer-text {
  margin-top: 10px; /* Space above the text */
  font-size: 14px; /* Smaller font size for footer text */
}
