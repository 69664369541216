/* src/components/LandingPage.css */

/* General page layout */
.landing-page {
    font-family: 'Arial', sans-serif;
    color: #333;
  }
  
  /* Hero Section */
  .hero {
    background-image: url('/public/assets/SolarPanel.jpg');
    background-size: cover;
    background-position: center;
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
  }
  
  .hero-content h1 {
    font-size: 3.5rem;
    margin-bottom: 1rem;
  }
  
  .hero-content p {
    font-size: 1.5rem;
    margin: 0;
  }
  
  /* About Us Section */
  .about {
    padding: 4rem 2rem;
    background-color: #f9f9f9;
  }
  
  .about h2 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  
  .about p {
    font-size: 1.1rem;
    line-height: 1.8;
  }
  
  /* Services Section */
  .services {
    padding: 4rem 2rem;
    background-color: #007bff;
    color: white;
  }
  
  .services h2 {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
  }
  
  .services-list {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
  }
  
  .service-item {
    background-color: #0056b3;
    padding: 2rem;
    border-radius: 8px;
    text-align: center;
  }
  
  .service-item h3 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }
  
  .service-item p {
    font-size: 1.1rem;
  }
  
  /* Projects Section */
  .projects {
    padding: 4rem 2rem;
    background-color: #f1f1f1;
  }
  
  .projects h2 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
  }
  
  .projects p {
    font-size: 1.1rem;
    line-height: 1.8;
    text-align: center;
  }
  
  /* Responsive Design */
  /* Tablet */
  @media (max-width: 768px) {
    .hero-content h1 {
      font-size: 2.5rem;
    }
  
    .hero-content p {
      font-size: 1.2rem;
    }
  
    .services-list {
      flex-direction: column;
      align-items: center;
    }
  
    .service-item {
      width: 100%;
    }
  }
  
  /* Mobile */
  @media (max-width: 480px) {
    .hero {
      height: 400px;
    }
  
    .hero-content h1 {
      font-size: 2rem;
    }
  
    .hero-content p {
      font-size: 1rem;
    }
  
    .services-list {
      gap: 1rem;
    }
  
    .service-item {
      padding: 1.5rem;
    }
  }
  